import api from '../plugins/axios';

function handleError(error) {
  console.error('API Error:', error);
}

export default {
  state: {
    currentTeam: {},
    currentTeamFeed: [],
    currentTeamChats: [],
    currentTeamActivity: [],
    currentTeamLeaderboard: [],
  },
  mutations: {
    setCurrentTeam(state, team) {
      state.currentTeam = team;
    },
    setCurrentTeamFeed(state, feed) {
      state.currentTeamFeed = feed;
    },
    setCurrentTeamChats(state, chats) {
      state.currentTeamChats = chats;
    },
    setCurrentTeamActivity(state, activity) {
      state.currentTeamActivity = activity;
    },
    setCurrentTeamLeaderboard(state, leaderboard) {
      state.currentTeamLeaderboard = leaderboard;
    },
  },
  actions: {
    async create({ dispatch }, team) {
      try {
        const resp = await api.post('/teams/create', team);
        if (resp.status === 200) {
          await dispatch('auth/getCurrentUser', {}, { root: true });
          return resp.data;
        }
      } catch (e) {
        handleError(e);
      }
    },
    async connectChat({ dispatch }, data) {
      try {
        const resp = await api.post('/teams/connect-chat', data);
        if (resp.status === 200) {
          await dispatch('auth/getCurrentUser', {}, { root: true });
          return resp.data;
        }
      } catch (e) {
        handleError(e);
      }
    },
    async getTeamById({ commit }, id) {
      try {
        const resp = await api.get('/teams/' + id);
        if (resp.status === 200) {
          commit('setCurrentTeam', resp.data);
          return resp.data;
        }
      } catch (e) {
        handleError(e);
      }
    },
    async getTeamFeedById({ commit }, id) {
      try {
        const resp = await api.get('/teams/' + id + '/points');
        if (resp.status === 200) {
          commit('setCurrentTeamFeed', resp.data);
          return resp.data;
        }
      } catch (e) {
        handleError(e);
      }
    },
    async getTeamActivityById({ commit }, id) {
      try {
        const resp = await api.get('/teams/' + id + '/activity');
        if (resp.status === 200) {
          commit('setCurrentTeamActivity', resp.data);
          return resp.data;
        }
      } catch (e) {
        handleError(e);
      }
    },
    async getTeamLeaderboardById({ commit }, id) {
      try {
        const resp = await api.get('/teams/' + id + '/leaderboard');
        if (resp.status === 200) {
          commit('setCurrentTeamLeaderboard', resp.data);
          return resp.data;
        }
      } catch (e) {
        handleError(e);
      }
    },
    async getChatsById({ commit }, id) {
      try {
        const resp = await api.get('/teams/' + id + '/chats');
        if (resp.status === 200) {
          if (resp.data.length > 0) {
            commit(
              'setCurrentTeamChats',
              resp.data.sort((a, b) => new Date(b.date) - new Date(a.date)),
            );
          } else {
            commit('setCurrentTeamChats', []);
          }
          return resp.data;
        }
      } catch (e) {
        handleError(e);
      }
    },
    async updateTeam({ commit }, data) {
      try {
        const resp = await api.put(`/teams/${data.id}`, data.team);
        if (resp.status === 200) {
          resp.data.isAdmin = true;
          commit('setCurrentTeam', resp.data);
          return resp.data;
        }
      } catch (e) {
        handleError(e);
      }
    },
    async clearFeedHistory({ commit }, id) {
      try {
        const resp = await api.delete(`/teams/${id}/points`);
        if (resp.status === 200) {
          commit('setCurrentTeamFeed', []);
        }
      } catch (e) {
        handleError(e);
      }
    },
    async deleteTeam({ commit }, id) {
      try {
        const resp = await api.delete(`/teams/${id}`);
        if (resp.status === 200) {
          commit('setCurrentTeam', {});
          commit('setCurrentTeamFeed', []);
          commit('setCurrentTeamChats', []);
        }
      } catch (e) {
        handleError(e);
      }
    },
    async deleteChat(_, id) {
      const resp = await api.delete(`/teams/chats/${id}`);
      if (resp.status != 204) {
        throw new Error('Failed to delete chat');
      }
    },
    async deletePoint(_, data) {
      const resp = await api.delete(`/teams/${data.teamId}/points/${data.pointId}`);
      if (resp.status != 204) {
        throw new Error('Failed to delete points');
      }
    },
    async editPoint(_, data) {
      try {
        const resp = await api.patch(`/teams/${data.id}/points/${data.pointId}`, {
          score: data.score,
          description: data.description,
        });
        if (resp.status === 200) {
          return resp.data;
        }
      } catch (e) {
        handleError(e);
      }
    },
    async moveChat(_, data) {
      try {
        const resp = await api.patch(`/teams/chats/${data.id}/move`, {
          teamId: data.teamId,
        });
        if (resp.status === 200) {
          return resp.data;
        }
      } catch (e) {
        handleError(e);
      }
    },
    async checkExistingChat(_, id) {
      try {
        const resp = await api.get('/chats/check/' + id);
        if (resp.status === 200) {
          return resp.data;
        }
      } catch (e) {
        handleError(e);
      }
    },
    async createTeamAndMoveChat(_, data) {
      try {
        const resp = await api.patch('/teams/chats/' + data.chatId + '/move-and-create', {
          title: data.title,
          locale: data.locale,
          emoji: data.emoji,
        });
        if (resp.status === 200) {
          return resp.data;
        }
      } catch (e) {
        handleError(e);
      }
    },
    async getSubscriptionLink(_, id) {
      try {
        const resp = await api.get(`/teams/${id}/payments/checkout`);
        if (resp.data.url) {
          return resp.data.url;
        }
      } catch (e) {
        handleError(e);
      }
    },
    async getBillingLink(_, id) {
      try {
        const resp = await api.get(`/teams/${id}/payments/billing`);
        if (resp.data.billingUrl) {
          return resp.data.billingUrl;
        }
      } catch (e) {
        handleError(e);
      }
    },
  },
  getters: {},
  namespaced: true,
};
